<template>
  <div>
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>{{ $t('backoffice.minorUpgrade.createImage.title') }}</template>
        <template v-slot:subtitle>{{ $t('backoffice.minorUpgrade.createImage.subtitle') }}</template>
      </ciam-card-header>

      <ciam-card-content>
        <ciam-card-content-lines>
          <ciam-card-content-line>
            <template v-slot:label>{{ $t('backoffice.minorUpgrade.createImage.versionId') }}</template>
            <template v-slot:content>
              <ciam-select
                v-model="form.keycloakVersionId"
                :options="versionOptions"
                required
                :loading="versionsLoading"
                :placeholder="$t('backoffice.minorUpgrade.createImage.versionIdPlaceholder')"
              />
            </template>
          </ciam-card-content-line>

          <ciam-card-content-line>
            <template v-slot:label>{{ $t('backoffice.minorUpgrade.createImage.image') }}</template>
            <template v-slot:content>
              <ciam-input
                v-model="form.image"
                type="text"
                required
                :placeholder="$t('backoffice.minorUpgrade.createImage.imagePlaceholder')"
              />
            </template>
          </ciam-card-content-line>

          <ciam-card-content-line>
            <template v-slot:label>{{ $t('backoffice.minorUpgrade.createImage.label') }}</template>
            <template v-slot:content>
              <ciam-input
                v-model="form.label"
                type="text"
                required
                :placeholder="$t('backoffice.minorUpgrade.createImage.labelPlaceholder')"
              />
            </template>
          </ciam-card-content-line>

          <ciam-card-content-line>
            <template v-slot:label>{{ $t('backoffice.minorUpgrade.createImage.index') }}</template>
            <template v-slot:content>
              <ciam-input
                v-model.number="form.index"
                type="number"
                required
                min="0"
              />
            </template>
          </ciam-card-content-line>

          <ciam-card-content-line>
            <template v-slot:label>{{ $t('backoffice.minorUpgrade.createImage.generalAvailability') }}</template>
            <template v-slot:content>
              <ciam-checkbox v-model="form.generalAvailability" />
            </template>
          </ciam-card-content-line>
        </ciam-card-content-lines>
      </ciam-card-content>

      <ciam-card-footer>
        <ciam-alert 
          v-if="problem" 
          :title="problem.title || $t('error.generic.title')"
          :description="problem.detail || problem.message || $t('error.generic.description')"
          :type="AlertStatus.ALERT" 
        />
        <ciam-button class="primary" :loading="isLoading" :disabled="!isValid" @click="createImage">
          {{ $t('backoffice.minorUpgrade.createImage.submit') }}
        </ciam-button>
      </ciam-card-footer>
    </ciam-card>
  </div>
</template>

<script>
import { AlertStatus } from '@/components/CiamAlert';
import BackofficeService from '@/pages/deployments/BackofficeService.js';
import CiamInput from '@/components/CiamInput.vue';
import CiamSelect from '@/components/CiamSelect.vue';
import CiamCheckbox from '@/components/themes_templates/CiamCheckbox.vue';
import CiamAlert from '@/components/CiamAlert.vue';
import CiamButton from '@/components/CiamButton.vue';
import CiamCard from '@/components/CiamCard.vue';
import CiamCardHeader from '@/components/CiamCardHeader.vue';
import CiamCardContent from '@/components/CiamCardContent.vue';
import CiamCardContentLine from '@/components/CiamCardContentLine.vue';
import CiamCardContentLines from '@/components/CiamCardContentLines.vue';
import CiamCardFooter from '@/components/CiamCardFooter.vue';
import { Notification } from 'vue-notifyjs';

export default {
  name: 'MinorUpgrade',
  components: {
    CiamInput,
    CiamSelect,
    CiamCheckbox,
    CiamAlert,
    CiamButton,
    CiamCard,
    CiamCardHeader,
    CiamCardContent,
    CiamCardContentLine,
    CiamCardContentLines,
    CiamCardFooter
  },
  data() {
    return {
      isLoading: false,
      versionsLoading: false,
      AlertStatus: AlertStatus,
      problem: null,
      versions: [],
      form: {
        keycloakVersionId: '',
        image: '',
        label: '',
        generalAvailability: false,
        index: 0
      }
    };
  },
  computed: {
    isValid() {
      return this.form.keycloakVersionId &&
             this.form.image &&
             this.form.label &&
             this.form.index >= 0;
    },
    versionOptions() {
      return [...this.versions]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(version => ({
          value: version.id,
          label: `${version.name} (${version.id})`
        }));
    }
  },
  async mounted() {
    await this.loadVersions();
  },
  methods: {
    async loadVersions() {
      this.versionsLoading = true;
      try {
        this.versions = await BackofficeService.getKeycloakVersions();
      } catch (error) {
        this.problem = error;
      } finally {
        this.versionsLoading = false;
      }
    },
    async createImage() {
      this.isLoading = true;
      this.problem = null;

      try {
        await BackofficeService.createKeycloakImage(this.form);
        Notification.notify({
          type: 'info',
          message: this.$t('backoffice.minorUpgrade.createImage.success')
        });
        this.resetForm();
      } catch (error) {
        this.problem = error;
      } finally {
        this.isLoading = false;
      }
    },
    resetForm() {
      this.form = {
        keycloakVersionId: '',
        image: '',
        label: '',
        generalAvailability: false,
        index: 0
      };
    }
  },
  watch: {
    async 'form.keycloakVersionId'(newVersionId) {
      if (newVersionId) {
        try {
          const latestImage = await BackofficeService.getLatestKeycloakImage(newVersionId);
          // Increment the index for the new image
          this.form.image = latestImage.image;
          this.form.label = latestImage.label;
          this.form.index = latestImage.index + 1;
          this.form.generalAvailability = latestImage.generalAvailability;
        } catch (error) {
          if (error.status !== 404) {
            this.problem = error;
          }
          // If no image found or error, reset form fields except version
          this.form.image = '';
          this.form.label = '';
          this.form.index = 0;
          this.form.generalAvailability = false;
        }
      }
    }
  }
};
</script> 